import LoadingComponent from "@components/Loading";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import { authPostData } from "@utils/request";
import { Button, Form, InputNumber, Modal } from "antd";
import { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
function ModalUpdateNumberSpin({
  closeModal,
  visible,
  currentRecord,
  getData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentRecord) {
      form.setFieldsValue({
        spinRelease: currentRecord?.spinRelease,
        spinMonth: currentRecord?.spinCount,
        enable: currentRecord?.isPremium,
      });
    }
  }, [currentRecord]);

  const handleCloseModal = () => {
    form.resetFields();
    closeModal();
  };

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      userId: currentRecord?.Id,
      enable: true,
    };
    authPostData({
      url: Endpoint.ENABLE_USER,
      method: "POST",
      payload: data,
      setLoading,
      setImportLoading: () => {},
      onSuccess: (res) => {
        if (res) {
          alertMessage(
            "success",
            t("Successful"),
            t("Update the number of successful spins")
          );
          getData();
          handleCloseModal();
        } else {
          alertMessage(
            "error",
            t("Error"),
            t("An error occurred. Please try again later")
          );
        }
      },
    });
  };
  const currentMonth = moment().format("MM");

  return (
    <Fragment>
      <Modal
        title={`${t("Update number spin")} ${t("month")} ${currentMonth}`}
        open={visible}
        onCancel={handleCloseModal}
        className="modal-uy-quyen"
        footer={[
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            form="form-add-spin"
          >
            {t("Update")}
          </Button>,
        ]}
      >
        <LoadingComponent
          loading={loading}
          children={
            <Form
              form={form}
              name="form-add-spin"
              layout="vertical"
              onFinish={handleSubmitData}
              initialValues={{}}
              children={
                <Fragment>
                  <Form.Item
                    rules={[{ required: true, message: "Please enter spin" }]}
                    label={t("Release spin")}
                    name="spinRelease"
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: t("Please enter spin") },
                    ]}
                    label={t("Monthly spin")}
                    name="spinMonth"
                  >
                    <InputNumber />
                  </Form.Item>
                </Fragment>
              }
            />
          }
        />
      </Modal>
    </Fragment>
  );
}

export default memo(ModalUpdateNumberSpin);
